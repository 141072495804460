ul, ol {
    margin: 0 0 15px;
	padding-left: 18px;
}

p {
    margin: 0 0 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
    text-decoration: none;
    outline: 0;
    transition: 0.3s;
	color: var(--tg-theme-link-color);

    &:hover, &:active {
		text-decoration: none;
    }

    &[disabled] {
		pointer-events: none;
    }
}
