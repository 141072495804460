.boxImage {
	border-radius: 5px;
	padding-left: 3px;
	background: var(--tg-theme-button-color);
	overflow: hidden;
	margin-top: 6px;
	box-shadow: var(--box-shadow);
	position: relative;
	z-index: 10;

	img {
		display: block;
		width: 100%;
	}
}

.boxText {
	margin-bottom: 15px;
	position: relative;
	z-index: 10;
    color: var(--tg-theme-text-color);

	&_header {
		margin-bottom: 6px;
		padding: 0 14px;
		color: var(--tg-theme-hint-color);
		text-transform: uppercase;
	}

	&_body {
		box-shadow: var(--box-shadow);
		border-radius: 10px;
		background: var(--tg-theme-bg-color);
		padding: 14px;
		word-wrap: break-word;
		margin-bottom: 6px;

		&:last-child {
			margin-bottom: 0;
		}

		& > * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&_footer {
		font-size: 14px;
		color: var(--tg-theme-hint-color);
		padding: 0 14px;
	}

	&_btn {
		width: 100%;
	}
}

.boxIntro {
	padding: 0 0 24px;
	text-align: center;
	position: relative;

	& > * {
		position: relative;
		z-index: 5;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_sticker {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 240px;
		height: 125px;
		margin-bottom: 15px;

		svg {
			display: block;
			max-width: 100%;
		}
	}

	&_title {
		font-size: 21px;
		font-weight: 600;
		margin-bottom: 10px;
		letter-spacing: 0.5px;
	}

	&_text {
		margin-bottom: 10px;
		font-size: 17px;
	}

	&_bgAnimation {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&_countdown {
		padding-top: 4px;
		margin-bottom: 10px;

		&-timer {
			color: var(--tg-theme-accent-text-color);
			// font-size: 24px;
			font-weight: 600;
			font-size: 21px;
			font-weight: 600;
			margin-bottom: 10px;
			letter-spacing: 0.5px;
		}

		&-text {
			color: var(--tg-theme-hint-color);
			font-size: 15px;
		}
	}

	.btn {
		margin-top: 14px;
	}
}

.boxList {
	margin-bottom: 15px;
	position: relative;
	z-index: 10;

	&_item {
		box-shadow: var(--box-shadow);
		border-radius: 10px;
		background: var(--tg-theme-bg-color);
		padding: 14px;
		display: flex;
		width: 100%;
		align-items: center;
		height: 72px;
		cursor: pointer;
		margin-top: 6px;

		&:first-child {
			margin-top: 0;
		}

		&-icon {
			margin-right: 11px;
			min-width: 46px;

			&--ticket {
				position: relative;

				&::before {
					content: '';
					width: 42px;
					height: 42px;
					position: absolute;
					bottom: 0;
					left: 0;
					background: var(--tg-theme-secondary-bg-color);
					border-radius: 100%;
					z-index: 1;
				}
			}

			img {
				width: 46px;
				height: 46px;
				border-radius: 100%;
				display: block;
				position: relative;
				z-index: 5;
			}

			span {
				padding-top: 1px;
				width: 46px;
				height: 46px;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 46px;
				font-weight: bold;
				text-transform: uppercase;
				font-size: 21px;
				color: #fff;
				position: relative;
				z-index: 5;
			}

			&--bg {
				background: var(--tg-theme-button-color);
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 46px;

				svg {
					display: block;
					width: 28px;
					height: 28px;
					color: var(--tg-theme-button-text-color);
				}
			}
		}

		&-icons {
			margin-right: 11px;
			height: 46px;
			min-width: 46px;
			position: relative;

			img {
				width: 46px;
				height: 46px;
				border-radius: 100%;
				display: block;
			}

			&--2 {
				img {
					width: 30px;
					height: 30px;
					border-radius: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;

					&:nth-child(2) {
						top: auto;
						left: auto;
						right: 0;
						bottom: 0;
						z-index: 1;
					}
				}
			}

			&--3 {
				img {
					width: 24px;
					height: 24px;
					border-radius: 100%;
					display: block;
					position: absolute;
					top: 10px;
					left: 0;
					z-index: 10;

					&:nth-child(2) {
						top: 0;
						left: auto;
						right: 0;
						z-index: 5;
					}

					&:nth-child(3) {
						top: auto;
						left: auto;
						right: 3px;
						bottom: 0;
						z-index: 1;
					}
				}
			}

			&--4 {
				img {
					width: 21px;
					height: 21px;
					border-radius: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 13px;
					z-index: 10;

					&:nth-child(2) {
						top: 13px;
						left: auto;
						right: 0;
						z-index: 6;
					}

					&:nth-child(3) {
						top: auto;
						bottom: 0;
						z-index: 4;
					}

					&:nth-child(4) {
						top: 13px;
						left: 0;
						z-index: 1;
					}
				}
			}

			&--5 {
				img {
					width: 20px;
					height: 20px;
					border-radius: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 13px;
					z-index: 10;

					&:nth-child(2) {
						top: 10px;
						left: auto;
						right: 0;
						z-index: 8;
					}

					&:nth-child(3) {
						top: auto;
						left: auto;
						bottom: 0;
						right: 5px;
						z-index: 6;
					}

					&:nth-child(4) {
						top: auto;
						bottom: 0;
						left: 5px;
						z-index: 4;
					}

					&:nth-child(5) {
						top: 10px;
						left: 0;
						z-index: 2;
					}
				}
			}
		}

		&-title {
			color: var(--tg-theme-text-color);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}

		&-hint {
			margin-top: 2px;
			font-size: 15px;
			color: var(--tg-theme-hint-color);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}

		&-text {
			margin-top: 2px;
			font-size: 14px;
			color: var(--tg-theme-hint-color);
			line-height: 1.1;
		}

		&-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin-right: auto;
			overflow: hidden;
		}

		&-right {
			margin-left: 10px;
		}
	}

	&_box {
		margin-top: 6px;
		position: relative;
		transition: 0.5s ease-in-out;

		&:first-child {
			margin-top: 0;
		}

		&-header {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: 10px;
			padding: 15px 14px 0;
			transition: inherit;
			width: 100%;
			height: 35px;
		}

		&-title {
			font-size: 18px;
			font-weight: 500;
			z-index: 1;
		}

		&-close {
			color: var(--tg-theme-link-color);
			display: flex;
			align-items: center;

			svg {
				color: inherit;
				width: 20px;
				height: 20px;
				margin-right: 5px;
				display: block;
				transform: rotate(180deg);
			}
		}

		&-open {
			position: absolute;
			top: 45px;
			left: 0;
			width: 100%;
			z-index: 10;
			transition: inherit;
			opacity: 1;

			&:hover, &:focus {
				box-shadow: var(--box-shadow);
			}
		}

		&-body {
			position: relative;
			z-index: 5;
			transition: inherit;

			& > * {
				transform: scale(0.91);
				transition: inherit;
				margin-top: -72px;
			}

			& > *:last-child {
				margin-top: -60px;
			}
		}

		$this: &;

		&--opened {
			height: auto;
			overflow: visible;

			#{$this}-header {
				top: 0;
			}

			#{$this}-open {
				top: 45px;
				opacity: 0;
			}

			#{$this}-body {
				z-index: 15;

				& > * {
					margin-top: 6px;
					transform: scale(1);
				}
			}
		}

		&--without-header {
			#{$this}-open {
				top: 0;
			}
		}
	}
}

.boxLink {
	text-align: center;
	border-radius: 10px;
	margin-bottom: 6px;
	padding: 0 1.0625rem;
    height: 44px;
    line-height: 44px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
    background: var(--tg-theme-secondary-bg-color);
    color: var(--tg-theme-text-color);
	cursor: pointer;
	padding-top: 1px;

	&--grey {
		color: var(--tg-theme-hint-color);
		font-weight: 500;
	}
}

.boxGrid {
	display: grid;
	column-gap: 6px;
	grid-template-columns: auto auto;
}

.boxFolder {
	border-radius: 10px;
    background: var(--tg-theme-bg-color);
	margin-bottom: 15px;
	box-shadow: var(--box-shadow);
	position: relative;
	z-index: 10;

	&Item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
		padding: 11px 14px;
		width: 100%;
		text-align: left;
		position: relative;

		&-count {
			min-width: 50px;
			margin-left: auto;
			display: flex;
			justify-content: flex-end;

			span {
				display: block;
				margin-left: auto;
				border-radius: 10px;
				color: var(--tg-theme-button-text-color);
				background: var(--tg-theme-button-color);
				border-radius: 0.75rem;
				padding: 0 0.4375rem;
				font-size: .875rem;
				min-width: 1.5rem;
				height: 1.5rem;
				line-height: 1.5rem;
				font-weight: 500;
				text-align: center;
			}

			&--place {
				span {
					background-color: var(--win-2);
				}
			}
		}

		&-info {
			overflow: hidden;
		}

		&-img {
			min-width: 46px;
			height: 46px;
			margin-right: 11px;

			span {
				padding-top: 1px;
				width: 46px;
				height: 46px;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 46px;
				font-weight: bold;
				text-transform: uppercase;
				font-size: 21px;
				color: #fff;
			}

			img {
				display: block;
				width: 46px;
				height: 46px;
				border-radius: 100%;
			}
		}

		&-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			color: var(--tg-theme-text-color);
		}

		&-ticket {
			color: var(--tg-theme-hint-color);
			margin-top: 2px;
			font-size: 15px;
		}

		&:last-child {
			border-bottom: 0;
		}

		$this: &;
	}
}

.boxTitle {
	margin-bottom: 6px;
	padding: 0 14px;
	color: var(--tg-theme-hint-color);
	text-transform: uppercase;
}

.boxButtons {
	border-radius: 10px;
    background: var(--tg-theme-bg-color);
	margin-bottom: 15px;
	box-shadow: var(--box-shadow);
	position: relative;
	z-index: 10;

	&Item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
		padding: 11px 14px;
		height: 44px;

		&-icon {
			min-width: 40px;

			svg {
				display: block;
				width: 22px;
				height: 22px;
				color: var(--tg-theme-accent-text-color);
			}
		}

		&-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			color: var(--tg-theme-accent-text-color);
		}
	}
}

.boxStat {
	margin-bottom: 6px;
	padding: 14px 7px;
	border-radius: 10px;
    background: var(--tg-theme-bg-color);
	box-shadow: var(--box-shadow);
	position: relative;
	z-index: 10;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-row-gap: 14px;

	&Item {
		padding: 0 7px;

		b {
			display: block;
			font-weight: bold;
			font-size: 18px;
			color: var(--tg-theme-text-color);
		}

		span {
			display: block;
			color: var(--tg-theme-hint-color);
			font-size: 12px;
		}
	}
}
