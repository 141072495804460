:root {
	--fontMain: 'Roboto', sans-serif;

	--tg-color-scheme: light;
    --tg-theme-bg-color: #ffffff;
    --tg-theme-text-color: #000000;
    --tg-theme-hint-color: #707579;
    --tg-theme-link-color: #3390ec;
    --tg-theme-button-color: #3390ec;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-secondary-bg-color: #f4f4f5;
    --tg-theme-header-bg-color: #ffffff;
    --tg-theme-accent-text-color: #3390ec;
    --tg-theme-section-bg-color: #ffffff;
    --tg-theme-section-header-text-color: #707579;
    --tg-theme-subtitle-text-color: #707579;
    --tg-theme-destructive-text-color: #e53935;
	--tg-viewport-height: 100vh;
	--tg-viewport-stable-height: 100vh;

	--box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
	--another-grey-transparent: rgba($color: #000000, $alpha: .05);

	--win-1: gold;
	--win-2: silver;
	--win-3: #CD7F32;

	.theme-dark {
		--another-grey-transparent: rgba($color: #ffffff, $alpha: .05);
	}
}
