.BottomSheet {
	z-index: 100 !important;

	&__draggable {
		max-width: 480px !important;
	}

	&__window-wrap {
		background-color: var(--tg-theme-secondary-bg-color) !important;
		padding: 52px 16px 16px;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}

	&__drag-indicator-wrap {
		display: none !important;
	}

	&Close {
		color: var(--tg-theme-accent-text-color);
		position: absolute;
		left: 16px;
		top: 16px;
		font-size: 17px;
	}

	&Title {
		color: var(--tg-theme-text-color);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 16px;
		font-size: 17px;
		font-weight: 500;
	}
}
