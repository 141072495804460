button {
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    border: 0;
    background: none;
    padding: 0;
	font-family: var(--fontMain);

    &:focus, &:active, &:hover {
        outline: 0;
        box-shadow: none;
    }
}

.btn {
	display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--tg-theme-button-text-color);
    background: var(--tg-theme-button-color);
    padding: 0 1.0625rem;
	border-radius: 10px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    transition: all .15s ease .15s;
	box-shadow: var(--box-shadow);

	&:disabled {
		background: var(--tg-theme-hint-color);
		cursor: not-allowed;
	}

	&:hover {
		text-decoration: none;
	}

	&--block {
		width: 100%;
	}

	&--sm {
		height: 24px;
		line-height: 24px;
		font-size: 14px;
		padding: 0 0.6rem;
		text-transform: none;
		border-radius: 20px;
	}

	&--tt-none {
		text-transform: none;
	}
}
